<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="열화관리점검 계획 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && !disabled && !data.sysApprovalRequestId"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-line
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  :plantCd="data.plantCd"
                  label="라인"
                  name="lineId"
                  v-model="data.lineId"
                  @datachange="datachange('A')"
                  @lineCode="lineCode">
                </c-line>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :disabled="disabled"
                  :editable="editable"
                  label="점검년월"
                  type="month"
                  name="checkDate"
                  v-model="data.checkDate"
                  @datachange="datachange('B')">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  label="점검명"
                  name="deteriorName"
                  v-model="data.deteriorName">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-field
                  required
                  :disabled="disabled"
                  :editable="editable"
                  :plantCd="data.plantCd"
                  label="점검자"
                  name="checkUserId"
                  v-model="data.checkUserId">
                </c-field>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
            </template>
          </c-card>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="열화관리 sheet 결과 목록"
            tableId="facilitySelfCheck"
            :columns="grid.columns"
            gridHeight="1100px"
            :data="grid.data"
            :editable="!disabled"
            :isFullScreen="false"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            @headerDataChange="headerDataChange"
          >
          <template slot="table-button">
            <q-btn-group>
              <c-btn
                v-if="editable && !disabled && !data.sysApprovalRequestId"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="suffixTitle">
              <font style="font-size:0.8em;font-weight:300;">
                <q-icon name="radio_button_unchecked" class="text-black"/>정상
                <q-icon name="close" class="text-black"/>이상
                <q-icon name="circle" class="text-black"/>청소
                V교환
                <q-icon name="check_box_outline_blank" class="text-black"/>마모
                △누유
                ▲필요
                <q-icon name="radio_button_checked" class="text-black"/>비가동
              </font>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'remark'">
                <c-text
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['remark']"
                  @dataChange="datachange1(props)"
                />
              </template>
              <template v-else>
                <c-select
                  stype="tableselect"
                  :editable="editable"
                  :comboItems="checkboxItems"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  label=""
                  name="checkItems"
                  v-model="props.row[col.name]"
                  @datachange="datachange2(props)"
                >
                </c-select>
              </template>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import mobileConfig from '@/js/mobileConfig';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-qr-deterior-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        deteriorCheckId: '',
        equipmentCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      year: '',
      grid: {
        merge: [
        ],
        columns: [],
        data: [],
      },
      data: {
        deteriorCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        deteriorName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        deteriorResultModelList: [], // sheet
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.deterior.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      lunarHolidays: [],
      today: '',
      beforeDay: '',
      currentMonth: '',
      lineCd: '',
      equipmentName: '',
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
        { code: 'CHK003', codeName: '●'},
        { code: 'CHK004', codeName: 'V'},
        { code: 'CHK005', codeName: '□'},
        { code: 'CHK006', codeName: '△'},
        { code: 'CHK007', codeName: '▲'},
        { code: 'CHK008', codeName: '⦿'},
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    curWeeks() {
      if (this.year === this.$comm.getThisYear()) {
        return this.$comm.moment().week()
      } else {
        return 0;
      }
    },
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
    isRemove() {
      return this.popupParam.deteriorCheckId && Boolean(!this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 170);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (String(this.today.charAt(0)) === '0') {
        this.today = this.today.substring(1)
      }
      // url setting
      this.getUrl = mobileConfig.sop.min.equipment.plan.deterior.get.mobile.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.deterior.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.plan.deterior.update.url;
      this.resultItemDetail = selectConfig.sop.min.equipment.result.deterior.newItem.url;
      this.lunarList = selectConfig.sop.min.equipment.result.lunar.url;
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.deteriorCheckId) {
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          deteriorCheckId: this.popupParam.deteriorCheckId,
          equipmentCd: this.popupParam.equipmentCd,
        }
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.lineCd = this.data.lineCd;

          if (this.data.deteriorResultModelList) {
            this.equipmentName = this.data.deteriorResultModelList[0].equipmentName;
            this.$_.forEach(this.data.deteriorResultModelList, _item => {
              if(_item['day' + this.today] && 
                  (this.$_.findIndex(this.checkboxItems, {code: _item['day' + this.today]}) === -1)) {
                _item.remark = _item['day' + this.today]
              }
            })
          }
          this.setColumns();
        },);
      }
    },
    saveData() {
      if (this.popupParam.deteriorCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.$_.forEach(this.grid.data, _item => {
                _item.regUserId = this.$store.getters.user.userId;
                _item.chgUserId = this.$store.getters.user.userId;
                if (!_item['day'+this.today]) {
                  this.$set(_item, 'day'+this.today, _item.remark)
                }
              })

              if (this.mappingType === 'POST') {
                this.$_.forEach(this.data.deteriorResultModelList, _item => {
                  _item.editFlag = 'C';
                })
              }
              this.data.deteriorResultModelList = this.$_.clone(this.grid.data)
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.deteriorCheckId = result.data
      this.getDetail();
    },
    datachange(type) {
      if (type === 'A' && this.data.lineId) {
        this.$http.url = this.$format(this.resultItemDetail, this.data.lineId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data.deteriorResultModelList = _result.data;
          this.$_.forEach(this.data.deteriorResultModelList, _item => {
            _item.deteriorCheckResultId = uid()
          })
          this.setColumns();
        },);
      }
      if (type === 'B' && this.data.checkDate) {
        // this.grid.data = [];
        this.setColumns();
      }
    },
    setColumns() {
      let _columns = [
        {
          name: 'checkItemPartName',
          field: 'checkItemPartName',
          label: '열화 관리 Part',
          align: 'center',
          style: 'width:120px',
          sortable: false,
          fix: true,
        },
        {
          name: 'checkStandard',
          field: 'checkStandard',
          label: '측정기준',
          align: 'center',
          style: 'width:120px',
          sortable: false,
          fix: true,
        },
      ]
      let days = [];
      let year = this.data.checkDate.substring(0, 4);
      let month = this.data.checkDate.substring(5);

      if (this.data.checkDate) {
        days.push(
          {
            name: 'day' + this.today,
            field: 'day' + this.today,
            label: '범례',
            currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
                      ? true : false, 
            align: 'center',
            setHeader: true,
            size: 'xs',
            style: 'width:80px',
            comboItems: this.checkboxItems,
            type: 'custom',
            headType: 'select',
            sortable: false,
          // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
          },
          {
            name: 'remark',
            field: 'remark',
            label: '수치입력',
            currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
                      ? true : false, 
            align: 'left',
            setHeader: true,
            style: 'width:80px',
            type: 'custom',
            headType: 'text',
            sortable: false,
          },
        )
        this.grid.columns = this.$_.concat(_columns, {
          name: 'today',
          field: 'today',
          label: this.today + '일',
          child: days
        })
      }
      this.grid.columns.push(
        {
          name: 'recentDay',
          field: 'recentDay',
          label: '이달최근점검',
          align: 'center',
          style: 'width:90px',
          sortable: false,
        },
        {
          name: 'checkItemCycleName',
          field: 'checkItemCycleName',
          label: '주기',
          align: 'center',
          style: 'width:50px',
          sortable: false,
        },
      )
      if (this.data.checkDate && this.data.lineId) {
        this.data.deteriorName = year + '년도  ' + month + '월  ' + this.lineCd + '  열화관리 Sheet'
      }
      this.grid.data = this.$_.clone(this.data.deteriorResultModelList)

      
      this.$_.forEach(this.grid.data, _item => {
        for (let i=1; i<this.today; i++) {
          if (_item['day'+i] && _item['day'+i] !== null) {
            _item.recentDay = i  + '일 [' + _item['day'+i+'Name']+ ']'
          }
        }
      })
    },
    lineCode(data) {
      this.lineCd = data;
    },
    /* eslint-disable no-unused-vars */
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    datachange2(props) {
      props.row['remark'] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    datachange1(props) {
      props.row['day' + this.today] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    headerDataChange(props, col) {
      if (col.name === String('day' + this.today)) {
        this.$_.forEach(this.grid.data, _item => {
          _item.remark = '';
        })
      } else if (col.name === 'remark') {
        this.$_.forEach(this.grid.data, _item => {
          this.$set(_item, String('day' + this.today), null)
        })
      }
    }
  }
};
</script>
